<template>
  <div class="session">
    <div class="session__container">
      <div class="session__text">
        <h2>THÔNG BÁO</h2>
        Tài khoản hết phiên đăng nhập hoặc đã đăng xuất
      </div>
      <div>
        <img
          src="../../assets/images/user-illustarator-1.png"
          alt="session time out"
        />
      </div>
      <div class="session__button">
        <a :href="url" class="session__button">Bấm để đăng nhập lại hệ thống</a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
const url = ref("");
onMounted(() => {
  url.value =
    localStorage.getItem("urlPrimary") +
    "login" +
    "?urlOrigin=" +
    encodeURIComponent(location.origin) +
    "&url=" +
    encodeURIComponent(localStorage.getItem("urlOld"));
});
</script>

<style scoped lang="scss">
.session {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  &__button {
    padding: 7px 15px;
    color: #fff;
    background-color: #409eff;
    width: fit-content;
    margin: auto;
    border-radius: 5px;
  }
  &__text {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 500;
  }
  &__button {
    margin-top: 20px;
  }
}

.session img {
  height: 400px;
  object-fit: contain;
}
</style>
